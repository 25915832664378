<template>
  <b-modal
    id="cookie-modal"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    modal-class="custom-modal2 modal-vertical-center"
    content-class="rectangular-dark-blue-modal expanded"
    @hidden="submitCookiePreferences"
    @shown="getCookieInfomation"
  >
    <template slot="modal-header">
      <h1>
        {{ $t("cookie_banner.headline") }}
      </h1>
      <DecoCorners color-variant="small-gold medium" />
    </template>
    <template slot="default">
      <p class="px-5">{{ $t("cookie_banner.bodytext") }}</p></template
    ><b-row id="cookie-select-row"
      ><b-col
        v-for="cookie in cookies"
        :key="cookie.type"
        class="cookie-select-col d-flex flex-column align-items-center"
        ><span class="cookie-type">{{
          $t(`cookie_banner.cookie_type.${cookie.type}`)
        }}</span>
        <div class="switch-container" @click="handleSwitchClick(cookie)">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <span
              class="text-uppercase primary-accent"
              :class="{ off: cookie.checked }"
              >{{ $t("no") }}</span
            ><span
              class="text-uppercase primary-accent"
              :class="{ off: !cookie.checked }"
              >{{ $t("yes") }}</span
            >
          </div>
          <div
            class="settings-controller"
            role="switch"
            :aria-checked="'' + cookie.checked"
            :aria-label="$t('no') + ' | ' + $t('yes')"
          >
            <div
              class="settings-indicator"
              :class="{ on: cookie.checked }"
            ></div>
          </div></div></b-col
    ></b-row>
    <b-row id="cookie-button-row" align-h="center" class="mt-5">
      <button class="button-blue-modern me-3" @click="hideModal">
        {{ $t("cookie_banner.button.confirm_selection") }}</button
      ><button class="ok-button-green" @click="handleSelectAll">
        {{ $t("cookie_banner.button.confirm_all") }}
      </button></b-row
    ><b-row id="cookie-link-row" class="mt-3" align-h="between"
      ><b-col class="text-start"
        ><router-link to="/imprint">{{
          $t("legal.imprint")
        }}</router-link></b-col
      ><b-col
        ><router-link to="/privacy">{{
          $t("legal.privacy")
        }}</router-link></b-col
      ><b-col class="text-end" @click="expanded = !expanded"
        ><span v-if="!expanded"
          >{{ $t("cookie_banner.button.expand_info") }} ↓</span
        ><span v-else>{{ $t("cookie_banner.button.hide_info") }} ↑</span></b-col
      ></b-row
    >
    <b-row v-if="expanded"
      ><b-col
        v-for="cookie in cookies"
        :key="cookie.type + 'details'"
        cols="12"
        class="text-start"
        ><span class="bold">{{
          $t(`cookie_banner.cookie_type.${cookie.type}`)
        }}</span>
        <p>
          {{ $t(`cookie_banner.expanded_text.${cookie.type}`) }}
        </p></b-col
      ></b-row
    >
  </b-modal>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
import * as Cookies from "vue-cookies";

export default {
  name: "CookieModal",
  components: { DecoCorners },
  data() {
    return {
      expanded: false,
      cookies: [],
    };
  },
  methods: {
    handleSwitchClick(cookie) {
      if (cookie.type === "essential") {
        return;
      }
      cookie.checked = !cookie.checked;
    },
    handleSelectAll() {
      this.cookies.forEach((cookie) => (cookie.checked = true));
      this.hideModal();
    },

    getCookieInfomation() {
      this.playSoundButtonCommon();
      this.cookies = this.$store.getters["cookieManager/getPreferences"];
    },

    submitCookiePreferences() {
      Cookies.set("kk-cookie-prefs", true, "30d", null, null, true, "none");
      this.$store.dispatch("cookieManager/setCookie", {
        name: "kk-prefs",
        data: this.cookies,
      });
      this.$store.dispatch("cookieManager/setPreferences", this.cookies);
      this.$root.$emit("cookie-prefs-updated");
    },
    hideModal() {
      this.$bvModal.hide("cookie-modal");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 808px;
$modal-height: auto;

#cookie-modal {
  padding-bottom: 128px;
  .modal-dialog {
    max-width: $modal-width;
    height: $modal-height;
    margin-top: -1%;
  }

  .modal-content {
    width: $modal-width;
    height: $modal-height;
    background-size: 100% 100%;
  }

  .modal-header {
    justify-content: center;
  }
  .modal-body {
    padding: 0 32px 32px 32px;
  }

  h1 {
    font-family: cinzel-decorative-bold;
    color: $text-light-gold;
    font-size: 30px;
  }
  #cookie-select-row {
    .cookie-select-col {
      .cookie-type {
        font-size: 22px;
      }
      .switch-container {
        width: 100px;
        &:hover {
          cursor: pointer;
        }
        span {
          &.off {
            color: #3a5162;
          }
        }
        .settings-controller {
          width: 100%;
          height: 32px;
          border: 2px solid $text-accent;
          border-radius: 20px;
          position: relative;
          &:hover {
            filter: $hover-shadow;
          }
          .settings-indicator {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: $text-accent;
            position: absolute;
            left: 3px;
            top: 3px;
            &.on {
              left: auto;
              right: 3px;
            }
          }
        }
      }
    }
  }
  #cookie-button-row {
    button {
      height: 44px;
      width: 280px;
      font-size: 22px;
      text-transform: none;
      border-radius: 0;
    }
  }
  #cookie-link-row {
    a,
    span {
      font-size: 18px;
      color: $text-accent;
      cursor: pointer;
      &:hover {
        filter: $hover-shadow;
        text-decoration: none;
      }
    }
  }
}
</style>

<template>
  <div
    :ref="itemRef"
    class="game-preview-item position-relative"
    :class="[itemClass, itemPosition, showDetails ? 'details-view' : '']"
    :name="'game' + game.id"
    v-on="{
      mouseenter: gameUnlocked ? handleMouseEnter : () => {},
      mouseleave: gameUnlocked ? handleMouseLeave : () => {},
    }"
  >
    <router-link
      :to="{ name: getName, params: { gameId: game.id } }"
      :event="gameUnlocked ? 'click' : ''"
      class="w-100 main-link-field"
      @click.native="playSoundButtonCommon"
    >
      <div class="slot-img-container position-relative">
        <img
          draggable="false"
          class="slot-img"
          :src="imgSrc"
          :alt="game.title"
          :class="[!gameUnlocked ? 'locked-game' : '']"
        />

        <div v-if="!gameUnlocked" class="locked-container">
          <div class="icon-unlock-level to-center-abs">
            {{ $t("lobby.game.locked", { 0: game.settings.unlock_level }) }}
          </div>
          <InfoBadge
            :id="'info-locked-game' + '-' + game.id + '-' + rowType"
            class="info-locked"
            :tooltip-only="true"
            position="center w-50 ms-5"
            :info-text="
              $t('info_badge.lobby.game.locked', {
                0: game.settings.unlock_level,
              })
            "
          />
        </div>
        <div
          v-if="jackpotCount && !showDetails"
          class="jackpot-panel bg-img mx-auto"
        >
          <div
            class="jackpot-number to-center-abs text-center w-100 bold text-white"
            :class="[jackpotTotalAmount.length > 15 ? 'smol' : '']"
          >
            {{ jackpotTotalAmount }}
          </div>
        </div>
        <div
          v-else-if="!jackpotCount && !showDetails"
          class="game-title-field w-100 text-uppercase text-normal d-flex align-items-center"
          :class="longTitle ? 'long-title' : ''"
        >
          {{ game.title }}
        </div>

        <div v-if="activeTournament || activeKingspath" class="slot-flag">
          <img draggable="false" alt="flag" :src="flagImg" />
          <InfoBadge
            :id="`info-event-game-${game.id}`"
            :tooltip-only="true"
            :info-text="popoverText"
            position="center"
          />
        </div>

        <div
          v-if="isNewSlot"
          class="new-flag"
          :class="$store.state.locale"
        ></div>

        <div v-if="!isNewSlot && isPlaceholder" class="suggest-flag">
          <div class="to-center-abs tip-text text-center text-white bold">
            TIP!
          </div>
        </div>

        <div v-if="!isNewSlot" class="extra-icon" :class="rowType">
          {{ gameIndex + 1 }}
        </div>
      </div>
    </router-link>

    <div v-if="showDetails" class="w-100 position-relative slot-bottom-row">
      <div class="d-inline-flex align-items-center mb-xl-3 mb-0">
        <router-link
          v-if="gameUnlocked"
          :to="{ name: getName, params: { gameId: game.id } }"
          @click.native="playSoundButtonCommon"
        >
          <div class="game-icon play bg-img clickable me-3"></div
        ></router-link>
        <favorite-badge :game-id="game.id" />
      </div>
      <div
        v-if="jackpotTotalAmount !== '0'"
        class="jackpot-panel bg-img position-absolute"
      >
        <div
          class="jackpot-number to-center-abs text-center bold text-white"
          :class="[jackpotTotalAmount.length > 15 ? 'smol' : '']"
        >
          {{ jackpotTotalAmount }}
        </div>
      </div>
      <div class="game-title-detail w-100 text-uppercase text-white bold">
        {{ game.title }}
      </div>
      <div
        v-if="gameTags && gameTags.length > 0"
        class="w-100 text-uppercase game-tag-container"
      >
        <router-link
          v-for="tag in gameTags"
          :key="tag.id"
          class="game-tag"
          :to="`/games/tag-${tag.id}-${tag.slug}`"
          :class="[
            gameTags.length > 1 && itemClass === 'featured-game' ? 'smol' : '',
          ]"
          >#{{ tag.title + " " }}</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import InfoBadge from "@/components/base/info-badge.vue";
import FavoriteBadge from "@/components/lobby/favorite-badge";

export default {
  name: "GamePreviewItem",
  components: { FavoriteBadge, InfoBadge },
  props: {
    game: {
      type: Object,
      required: true,
    },
    kingspathOnGame: {
      type: String,
      required: false,
      default: "",
    },

    activeTournamentGameIds: {
      type: Array,
      required: false,
      default: null,
    },

    userCanPlayTournaments: {
      type: Boolean,
      default: false,
    },
    gridView: {
      type: Boolean,
      default: false,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    gridArrangement: {
      type: String,
      default: "",
    },
    gameIndex: {
      type: Number,
      required: true,
    },
    rowType: {
      type: String,
      default: "",
    },
    isPlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemWidth: 0,
      itemHeight: 0,
      itemPosition: "",
      showDetails: false,
      slotJackpotsOnGame: null,
    };
  },

  computed: {
    imgSrc() {
      let gameImages = this.game.images;
      if (!gameImages || gameImages.length === 0) {
        return this.$whitelabel.assets.logos.slotPlaceholder;
      } else {
        if (this.itemClass === "standard") {
          return this.findGameImage("GRID_2x2");
        } else if (
          this.itemClass === "full-height" ||
          this.itemClass === "featured-game"
        ) {
          return this.findGameImage("GRID_2x1");
        } else {
          return this.findGameImage("GRID_2x2");
        }
      }
    },
    itemRef() {
      return `game${this.game.id}`;
    },

    itemClass() {
      if (this.gridArrangement === "1_ROW_5_GAMES") {
        return "featured-game";
      }
      if (this.gridArrangement === "2_ROWS_2_FEATURES" && this.gameIndex <= 1) {
        return "full-height half-width";
      } else if (
        this.gridArrangement === "2_ROWS_1_FEATURE_HIGH" &&
        this.gameIndex === 0
      ) {
        return "half-width full-height";
      } else if (
        (this.gridArrangement === "2_ROWS_2_FEATURES_HIGH" &&
          this.gameIndex <= 1) ||
        (this.gridArrangement === "2_ROWS_4_FEATURES_HIGH" &&
          this.gameIndex <= 3)
      ) {
        return "full-height";
      }
      return "standard";
    },

    gameUnlocked() {
      return (
        !this.game.settings ||
        this.game.settings.unlock_level <=
          this.$store.getters["user/currentUser"].level
      );
    },

    jackpotCount: function () {
      if (this.slotJackpotsOnGame === null) {
        return;
      }

      return this.slotJackpotsOnGame.length;
    },
    hasJackpot() {
      return this.jackpotCount > 0 && this.slotJackpotsUnlocked;
    },

    jackpotTotalAmount: function () {
      if (!this.slotJackpotsOnGame) {
        return "0";
      }

      let amount = 0;
      this.slotJackpotsOnGame.forEach((jackpot) => {
        amount += jackpot.amount_current;
      });

      return this.formatNumber(Math.round(amount));
    },

    activeTournament() {
      if (!this.activeTournamentGameIds.length) {
        return;
      }

      return this.activeTournamentGameIds.includes(this.game.id);
    },

    activeKingspath() {
      //  TODO: update property
      return false;
    },

    flagImg() {
      if (this.activeTournament) {
        return require("@/assets/img/slot/icn-tournament-active.svg");
      } else if (this.activeKingspath) {
        return require("@/assets/img/lobby/slot-kingspath.png");
      } else {
        return "";
      }
    },
    getName() {
      if (this.activeTournament) {
        return "tournament-game";
      } else if (this.activeKingspath) {
        return "kingspath-game";
      } else {
        return "game";
      }
    },
    popoverText() {
      if (this.activeTournament) {
        return this.$t("lobby.tournament_active");
      } else if (this.activeKingspath) {
        return this.$t("lobby.kingspath_active");
      } else {
        return "";
      }
    },
    longTitle() {
      return this.game.title.length >= 30;
    },

    isNewSlot() {
      // TODO: Get isNew info
      return false;
    },

    gameTags() {
      return this.game.game_tags;
    },
  },
  mounted() {
    this.itemWidth = this.$refs[this.itemRef].clientWidth;
    this.itemHeight = this.$refs[this.itemRef].clientHeight;

    this.getSlotJackpotsOnGame();
  },

  methods: {
    getSlotJackpotsOnGame() {
      this.slotJackpotsOnGame = this.$store.state.gameplay.slotJackpots.filter(
        (g) => g.game_id === this.game.id
      );
    },
    handleMouseEnter() {
      this.showDetails = true;
    },
    handleMouseLeave() {
      this.showDetails = false;
    },

    findGameImage(imageType) {
      let gameImages = this.game.images;
      let gameImage = gameImages.find((image) => image.type === imageType);
      if (!gameImage) {
        let standardImg = gameImages.find((image) => image.type === "GRID_2x2");
        if (!standardImg) {
          return this.$whitelabel.assets.logos.slotPlaceholder;
        }
        return standardImg.url;
      } else return gameImage.url;
    },
  },
};
</script>
<style scoped lang="scss">
.game-preview-item {
  width: 100%;
  height: 100%;
  z-index: 1;
  &:hover {
    a {
      text-decoration: none;
    }
  }

  &.details-view {
    width: 370px;
    height: 350px;
    z-index: 100;
    background-color: #141b28;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7px 7px 14px 7px;
    box-shadow: 0 0 8px white, 0 0 32px $text-shadow--cyan,
      0 0 16px $text-shadow--cyan, 0 0 8px $text-shadow--cyan,
      0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
    transition: box-shadow 0.5s ease-in;
    .slot-img-container {
      .slot-img {
        height: 260px;
        width: 355px;
        max-width: 100%;
        max-height: 100%;
      }
    }
    @media (max-width: 1280px) {
      width: 134%;
      height: 160%;

      .slot-img-container {
        .slot-img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.full-width,
    &.half-width,
    &.full-height,
    &.featured-game {
      .main-link-field {
        height: 85%;
        width: 100%;
      }
      .slot-img-container {
        .slot-img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.full-width,
    &.half-width,
    &.full-height {
      justify-content: space-around;
    }
    &.featured-game {
      width: 120%;
      height: 125%;
      padding: 7px 7px 45px 7px;

      @media (max-width: 1430px) {
        width: 140%;
        height: 140%;
      }
      @media (max-width: 1100px) {
        width: 170%;
        height: 170%;
      }
    }
    &.full-height {
      width: 120%;
      height: 120%;
    }

    &.full-width,
    &.half-width {
      width: 101%;
      height: 137%;
      .main-link-field {
        height: 70%;
      }
    }

    &.full-height.half-width,
    &.full-height.full-width {
      width: 104%;
      height: 110%;
      .main-link-field {
        height: 80%;
      }
    }
    &.full-height,
    &.featured-game,
    &.half-width {
      .jackpot-panel {
        bottom: 60%;
      }
    }
    @media (max-width: 1430px) {
      .slot-bottom-row {
        .jackpot-panel {
          bottom: 74%;
        }
      }
    }
  }
  &.full-height {
    grid-row: span 2;
    .slot-img-container {
      .slot-img {
        height: 489px;
      }
    }

    @media (min-width: 1600px) {
      .slot-img-container {
        .slot-img {
          object-position: center;
        }
      }
    }
  }
  &.full-width {
    grid-column: span 4;
    .slot-img-container {
      .slot-img {
        width: 100%;
      }
    }
  }
  &.half-width {
    grid-column: span 2;
    .slot-img-container {
      .slot-img {
        width: 100%;
      }
    }
  }
  &.featured-game {
    .slot-img-container {
      .slot-img {
        width: 100%;
        height: 352px;
        @media (max-width: 1430px) {
          height: auto;
          max-height: 100%;
        }
      }
    }
  }

  .game-title-field {
    height: 50px;
    color: white;
    font-family: Ubuntu-medium;
    font-size: 17px;
    text-shadow: 1px 2px 4px black;
    line-height: 1.2;
  }
  .jackpot-panel {
    width: 203px;
    height: 79px;
    top: -25px;
    z-index: 15;
    background-image: url(~@/assets/img/lobby/panel-jackpot.png);
    position: relative;
    .jackpot-number {
      top: 6px;
      font-size: 20px;
      text-shadow: 0 2px 12px $text-accent, 0 1px 5px black, 0 1px 5px black,
        0 1px 3px black;
      &.smol {
        top: 8px;
        font-size: 16px;
      }
    }
  }

  .lock {
    z-index: 5;
    height: 144px;
  }

  .lock-text {
    font-size: 21px;
    z-index: 6;
    top: 0;
    width: 178px;
    height: 32px;
    border-radius: 2.1px;
    color: white;
    background-image: linear-gradient($text-red-line, #3a0000);
    box-shadow: inset 0 0 4px $text-red-line, inset 0 0 8px $text-red-line;
  }

  .game-locked {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .slot-img-container {
    height: 100%;
    width: 100%;

    .slot-img {
      height: 217px;
      width: 296px;
      object-fit: cover;
      object-position: center;
      @media (max-width: 1430px) {
        width: 100%;
        height: auto;
        max-height: 100%;
      }
      &.locked-game {
        -webkit-filter: grayscale(100%) brightness(50%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%) brightness(50%);
        cursor: default;

        @supports not (filter: grayscale(100%) brightness(50%)) {
          &:before {
            content: "";
            background-color: rgba(136, 136, 136, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }

    .locked-container {
      position: absolute;
      width: 100%;
      height: 14.5rem;
      margin: auto;
      top: -120px;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: default;

      .icon-unlock-level {
        top: 50px;
        width: 14.5rem;
        height: 14.5rem;
        background: url(~@/assets/img/lobby/icn-locked-slot.png) center/100%
          100% no-repeat;
        color: white;
        font-family: Ubuntu-Medium;
        font-size: 1.125rem;
        padding-top: 8.25rem;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  .slot-flag {
    position: absolute;
    left: -5px;
    height: 48px;
    top: -5px;
    img {
      height: 100%;
    }
  }

  .new-flag {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 74px;
    height: 99px;
    z-index: 10;

    &.de {
      background: url(~@/assets/img/lobby/icn-flag-new-de.png) center/100% 100%
        no-repeat;
    }
    &.en {
      background: url(~@/assets/img/lobby/icn-flag-new-en.png) center/100% 100%
        no-repeat;
    }
  }

  .suggest-flag {
    position: absolute;
    top: -15px;
    right: -3px;
    width: 80px;
    height: 96px;
    z-index: 10;

    background: url(~@/assets/img/lobby/icn-flag-suggest.png) center/100% 100%
      no-repeat;
    .tip-text {
      top: 48px;
      font-size: 14px;
      text-shadow: 0 0 4px black;
    }
  }

  .extra-icon {
    position: absolute;
    top: -11.5px;
    right: 5px;
    display: none;
    z-index: 5;

    &.top_10_games {
      display: block;
      background: url(~@/assets/img/lobby/icn-flag-top-10.png) center/100% 100%
        no-repeat;
      width: 84px;
      height: 84px;
      color: white;
      font-size: 36px;
      font-family: Ubuntu-Bold;
      padding: 15px 10px 10px 10px;
      text-align: center;
      text-shadow: 0 0 6px black;
    }
  }
  .slot-bottom-row {
    padding-top: 14px;
    .game-title-detail {
      font-size: 17px;
    }

    .game-tag-container {
      line-height: 1.2;

      .game-tag {
        color: #6c6c6c;
        font-size: 16px;
        &.smol {
          font-size: 12px;
        }
        &:hover {
          color: white;
        }
        @media (max-width: 1430px) {
          font-size: 12px;
        }
      }
    }
    .jackpot-panel {
      right: -15px;
      bottom: 35%;
      pointer-events: none;
    }
  }

  .game-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    &.play {
      background-image: url(~@/assets/img/slot/icn-play.svg);
    }
  }
  .jackpot-panel {
    width: 203px;
    height: 79px;
    background-image: url(~@/assets/img/lobby/panel-jackpot.png);

    .jackpot-number {
      top: 15px;
      font-size: 20px;
      text-shadow: 0 2px 12px $text-accent, 0 1px 5px black, 0 1px 5px black,
        0 1px 3px black;
      &.smol {
        top: 17px;
        font-size: 16px;
      }
    }
    @media (max-width: 1430px) {
      width: 140px;
      height: 54px;
      .jackpot-number {
        font-size: 18px;
        top: 6px;
        &.smol {
          font-size: 12px;
          top: 11px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.game-preview-item {
  .info-locked {
    height: 100%;
    width: 100%;

    .info-badge-orange {
      height: 100%;
      width: 11rem !important;
      cursor: default;
      top: 27%;
      left: 0;
      right: 0;
      margin: auto !important;
    }
  }
}
</style>

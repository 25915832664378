<template>
  <div id="slot-sorting" class="container-fluid px-0 pt-0 pb-3">
    <b-row
      id="sorting-content"
      class="mx-auto"
      no-gutters
      align-v="center"
      align-h="around"
    >
      <b-col
        id="play-random-slot-button"
        cols="1"
        class="clickable me-3"
        @click="handleRandomClick"
      ></b-col>
      <b-col class="h-100"
        ><router-link to="/" class="sorting-item">Lobby</router-link></b-col
      ><b-col class="h-100"
        ><router-link to="/games/all" class="sorting-item">{{
          $t("sorting.all_games")
        }}</router-link></b-col
      >

      <b-col id="search-area" class="h-100 sorting-item position-relative p-0">
        <input
          id="sort-input"
          v-model="searchTerms"
          class="w-100"
          @input="handleSearchInput"
        />
        <img
          draggable="false"
          :src="searchInputIcon"
          class="clickable"
          @click="handleIconClick"
        />
      </b-col>
    </b-row>
    <RandomSlotPopup @feel-lucky="startRandomSlot" />
    <b-row
      v-if="results === 0 && searchActive"
      id="not-found-row"
      no-gutters
      align-h="center"
      class="p-3"
      ><span class="text-uppercase">{{
        $t("sorting.search_term_not_found")
      }}</span></b-row
    >

    <!--    provider filters-->
    <b-row id="filter-row" class="mt-5">
      <b-col cols="12">
        <b-row class="filters" align-h="center" align-v="center">
          <b-col
            v-for="provider in providersArr"
            :key="provider.title"
            cols="6"
            sm="3"
            xl="3"
            :title="provider.provider"
            class="p-0 d-flex justify-content-between align-items-center"
          >
            <div
              class="partner-logo mx-auto clickable"
              :class="[
                provider.img,
                $route.params.providerId === provider.id ? 'on' : 'off',
              ]"
              @click="filterByProvider(provider.id)"
            ></div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { fetchRandomGame } from "@/API/games.js";
import RandomSlotPopup from "@/components/lobby/random-slot-popup.vue";

export default {
  name: "SlotSorting",
  components: { RandomSlotPopup },
  props: {
    results: {
      type: Number,
      default: 1,
    },
    gridView: {
      type: Boolean,
      default: false,
    },

    selectedRow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      searchTerms: "",
      searchActive: false,
      providersArr: [
        {
          id: "2",
          provider: "Gamomat",
          img: "gamomat",
          title: "Gamomat " + this.$t("filter.games"),
        },
        {
          id: "13",
          provider: "Ballywulff",
          img: "ballywulff",
          title: "Ballywulff " + this.$t("filter.games"),
        },
        {
          id: "11",
          provider: "Only Play",
          img: "onlyplay",
          title: "Only Play " + this.$t("filter.games"),
        },
        {
          id: "10",
          provider: "Apparat",
          img: "apparat",
          title: "Apparat" + this.$t("filter.games"),
        },
        {
          id: "7",
          provider: "Netent",
          img: "netent",
          title: "Netent" + this.$t("filter.games"),
        },
        {
          id: "6",
          provider: "Spinomenal",
          img: "spinomenal",
          title: "Spinomenal" + this.$t("filter.games"),
        },
        {
          id: "12",
          provider: "Hoelle Games",
          img: "hoelle",
          title: "Hoelle " + this.$t("filter.games"),
        },
      ],
    };
  },
  computed: {
    searchInputIcon() {
      if (this.searchTerms.length === 0) {
        return require("@/assets/img/icons/search-white.svg");
      } else return require("@/assets/img/buttons/close-slot.svg");
    },
  },

  watch: {
    searchTerms(newSearch, oldSearch) {
      if (newSearch.length < oldSearch.length && newSearch.length === 0) {
        this.$emit("start");
      }
    },
  },
  methods: {
    filterByProvider(id) {
      if (this.$route.params.providerId === id) {
        this.$router.push("/");
        return;
      }

      this.$router.push("/games-by-provider/" + id);
    },
    handleSearchInput() {
      this.searchActive = true;
      this.$emit("search", this.searchTerms);
    },

    handleIconClick() {
      if (this.searchActive) {
        if (!this.gridView) return;
        if (this.searchTerms.length > 0) {
          this.searchTerms = "";
          this.searchActive = false;
          this.$emit("start");
        }
      } else return;
    },
    rowTitle(row) {
      switch (row.type) {
        case "PUBLISHER_GAMES":
          return row.details.publisher.name;
          break;
        case "TAG_GAMES":
          return row.name;
          break;

        default:
          return this.$t(`lobby.layout_model_type.${row.type}`);
      }
    },

    handleRandomClick() {
      if (this.$store.state.showRandomSlotPopup) {
        this.$bvModal.show("random-slot-popup");
      } else {
        this.startRandomSlot();
      }
    },

    startRandomSlot() {
      let randomGameId;
      fetchRandomGame()
        .then((res) => {
          randomGameId = res.data.data.game.id;
        })
        .catch((e) => {
          // use frontend random functionality as fallback
          const games = this.$store.getters["gameplay/playableGames"];
          const maxNum = games.length;
          let index = Math.floor(Math.random() * (maxNum + 1));
          randomGameId = games[index].id;
          console.log(e);
        })
        .finally(() =>
          this.$router.push({ name: "game", params: { gameId: randomGameId } })
        );
    },
  },
};
</script>
<style lang="scss">
#slot-sorting {
  #sorting-content {
    height: 35px;
    color: #5499a4;
    text-transform: uppercase;
    font-family: Ubuntu-medium;
    width: 500px;

    z-index: 11;
    font-size: 14px;
    #play-random-slot-button {
      height: 100%;
      background: url(~@/assets/img/icons/random.svg) center/100% 100% no-repeat;
    }

    .sorting-item,
    .select-item {
      background-image: linear-gradient(
        $btn-gadient-midnight,
        $btn-gradient-blend
      );
      height: 100%;
      max-width: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Ubuntu-medium;
      color: #5e707c;

      &:hover,
      &.router-link-exact-active {
        text-decoration: none;
        color: white !important;
        text-shadow: 0 0 8px $btn-text-shadow-turquoise;
        background-image: linear-gradient(
          rgba(22, 63, 81, 0.7),
          rgba(5, 13, 28, 0.7)
        );
      }

      .row-select {
        list-style-type: none;
        left: 0;
        top: 35px;
        .select-item {
          z-index: 100;
        }
      }
    }
    #search-area {
      img {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 16px;
      }

      #sort-input {
        border-radius: 0;
        border: none;
        font-size: 14px;
        font-family: Ubuntu-medium;
        background-color: transparent;
        height: 100%;
        background-image: linear-gradient(
          $btn-gadient-midnight,
          $btn-gradient-blend
        );
        color: $text-accent;
        padding-left: 10px;
        &:focus-visible,
        &:focus {
          outline: none;
        }
      }
    }
  }

  #filter-row {
    .filter-text {
      font-size: 20px;
    }

    .filters {
      font-size: 20px;
      color: white;
      .partner-logo {
        margin-bottom: 15px;
        height: 60px;

        &.gamomat {
          width: 276px;

          &.on {
            background: url(~@/assets/img/provider/Gamomat-active.png)
              center/100% 100% no-repeat;
          }
          &.off {
            background: url(~@/assets/img/provider/Gamomat-inactive.png)
              center/100% 100% no-repeat;
          }
        }

        &.ballywulff {
          width: 112px;
          &.on {
            background: url(~@/assets/img/provider/BallyWulff-active.png)
              center/100% 100% no-repeat;
          }
          &.off {
            background: url(~@/assets/img/provider/BallyWulff-inactive.png)
              center/100% 100% no-repeat;
          }
        }
        &.onlyplay {
          width: 218px;
          &.on {
            background: url(~@/assets/img/provider/Onlyplay-active.png)
              center/100% 100% no-repeat;
          }
          &.off {
            background: url(~@/assets/img/provider/Onlyplay-inactive.png)
              center/100% 100% no-repeat;
          }
        }
        &.apparat {
          width: 218px;
          &.on {
            background: url(~@/assets/img/provider/Apparat-active.png)
              center/100% 100% no-repeat;
          }
          &.off {
            background: url(~@/assets/img/provider/Apparat-inactive.png)
              center/100% 100% no-repeat;
          }
        }
        &.netent {
          width: 168px;
          &.on {
            background: url(~@/assets/img/provider/Netent-active.png)
              center/100% 100% no-repeat;
          }
          &.off {
            background: url(~@/assets/img/provider/Netent-inactive.png)
              center/100% 100% no-repeat;
          }
        }
        &.spinomenal {
          width: 256px;
          &.on {
            background: url(~@/assets/img/provider/spinomenal-active.png)
              center/100% 100% no-repeat;
          }
          &.off {
            background: url(~@/assets/img/provider/spinomenal-inactive.png)
              center/100% 100% no-repeat;
          }
        }
        &.hoelle {
          width: 168px;
          &.on {
            background: url(~@/assets/img/provider/hoelle-active.png)
              center/100% 100% no-repeat;
          }
          &.off {
            background: url(~@/assets/img/provider/hoelle-inactive.png)
              center/100% 100% no-repeat;
          }
        }
      }
    }
  }
  #not-found-row {
    background-color: $bg-gradient-navy-blue;
    color: $text-accent;
  }
}
</style>

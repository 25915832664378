<template>
  <div translate="no">
    <h1>Datenschutzerklärung {{ $whitelabel.branding.productName }}</h1>
    <p>Stand: 28.10.2024</p>
    <p>
      Im Folgenden findest du alle Informationen über die Verarbeitung deiner
      personenbezogenen Daten durch die
      {{ $whitelabel.branding.productName }} Anwendung (nachfolgend „App“) und
      die dir nach dem Datenschutzrecht zustehenden Rechte. Gem. Art. 13 DSGVO
      möchten wir Dich über die Verarbeitung deiner Daten informieren.
    </p>
    <p>
      Die folgenden Hinweise sollen dich darüber informieren, wofür wir deine
      personenbezogenen Daten verwenden, um welche Daten es sich handelt und
      welche Rechte dir in Bezug auf die Datenverarbeitung zustehen.
      Personenbezogene Daten i.S.d. Art. 4 Nr. 1 DSGVO (nachfolgend „Daten“)
      sind alle Informationen, die zumindest mittelbar die Identifikation einer
      Person zulassen oder sich auf eine bereits identifizierte Person beziehen.
    </p>
    <h2>1. Verantwortlicher</h2>
    <p>Du erreichst uns wie folgt:</p>
    <p>
      <b>{{ $whitelabel.branding.companyName }} </b><br />
      Lange Straße 15 <br />29664 Walsrode
      <br />
      E-Mail:
      <a href="mailto:info@gluecksfgaktor7.com">info@gluecksfgaktor7.com</a
      ><br />
      Telefon: 05161 787 49 16
    </p>
    <p>
      Solltest Du weitere Fragen zur Erhebung, Verarbeitung und Nutzung deiner
      persönlichen Daten haben, wende dich gern an
      <a href="mailto:datenschutz@gluecksfaktor7.com"
        >datenschutz@gluecksfaktor7.com</a
      >
    </p>
    <h2>2. Automatisch verarbeitete Daten‍</h2>
    <p>
      Im Rahmen Ihrer Nutzung der App erheben wir bestimmte Daten automatisch,
      die für die Nutzung der App erforderlich sind. Hierzu gehören:
    </p>
    <ul>
      <li>Interne Geräte-ID</li>
      <li>IP-Adresse</li>
      <li>Version Ihres Betriebssystems</li>
      <li>Zeitpunkt des Zugriffs</li>
      <li>Anfragezeitpunkt</li>
      <li>Allgemein Logdaten</li>
    </ul>
    <p>
      Diese Daten werden automatisch an uns übermittelt, aber nicht
      gespeichert, 
    </p>
    <p></p>
    <ol>
      <li>
        um Ihnen den Dienst und die damit verbundenen Funktionen zur Verfügung
        zu stellen; 
      </li>
      <li>die Funktionen und Leistungsmerkmale der App zu verbessern und</li>
      <li>Missbrauch sowie Fehlfunktionen vorzubeugen und zu beseitigen.</li>
    </ol>

    <p>Diese Datenverarbeitung ist dadurch gerechtfertigt, dass</p>
    <p></p>
    <ol>
      <li>
        Verarbeitung für die Erfüllung des Vertrags zwischen Ihnen als
        Betroffener und uns gemäß Art. 6 Abs. 1 lit. b DSGVO zur Nutzung der App
        erforderlich ist, und 
      </li>
      <li>
        wir ein berechtigtes Interesse daran haben, die Funktionsfähigkeit und
        den fehlerfreien Betrieb der App zu gewährleisten und einen markt- und
        interessengerechten Dienst anbieten zu können, dass hier Ihre Rechte und
        Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art. 6
        Abs. 1 lit. f DSGVO überwiegt.<br />Wir zudem ein berechtigtes Interesse
        an der sicheren Verarbeitung der Daten haben sowie dem Schutz unserer
        IT-Infrastruktur (IT-Sicherheit und Informationssicherheit) gemäß Art. 6
        Abs. 1 lit. f DSGVO.
      </li>
    </ol>

    <h2>3. Zweckbestimmung und Rechtsgrundlagen</h2>
    <p>
      Wir verarbeiten darüber hinaus deine Daten nur zu den unten genannten
      Zwecken:
    </p>

    <ul>
      <li>
        Um mit dir in Kontakt zu treten oder um Serviceanfragen zu bearbeiten
        (Art. 6 Abs. 1 lit. b DSGVO): Wenn du eine Anfrage stellst oder einen
        Service in Anspruch nimmst, verarbeiten wir deine Daten, um diese
        Anfrage zu beantworten oder den Service durchzuführen.
      </li>
      <li>
        Für Gewinnspiele (Art. 6 Abs. 1 lit. b DSGVO): Wenn du an unseren
        Gewinnspielen teilnimmst, verwenden wir deine Daten, um die Teilnahme
        und Ausgabe der Preise zu verwalten.
      </li>
      <li>
        Für Werbemaßnahmen (Art. 6 Abs. 1 lit. a DSGVO, falls du deine
        ausdrückliche Zustimmung gegeben hast, oder Art. 6 Abs. 1 lit. f DSGVO,
        wenn wir ein berechtigtes Interesse daran haben): Deine Daten können wir
        zur Direktwerbung nutzen, entweder mit deiner Zustimmung oder wenn wir
        ein berechtigtes Interesse haben, dich über unsere Angebote zu
        informieren.
      </li>
      <li>
        Um dir Zugang zu bestimmten Informationen oder Angeboten bereitzustellen
        (Art. 6 Abs. 1 lit. a oder b DSGVO): Manchmal benötigen wir deine Daten,
        um dir Zugang zu bestimmten Informationen oder Angeboten zu ermöglichen.
        Dabei kann es sich um eine Einwilligung deinerseits handeln oder es kann
        im Rahmen eines Vertrages erforderlich sein.
      </li>
      <li>
        Um gesetzliche Verpflichtungen zu erfüllen (Art. 6 Abs. 1 lit. c und f
        DSGVO): In bestimmten Fällen können wir rechtlich dazu verpflichtet
        sein, deine Daten zu verarbeiten oder es könnte in unserem berechtigen
        Interesse liegen, dies zu tun.
      </li>
      <li>
        Im Rahmen anderer berechtigter Interessen (Art. 6 Abs. 1 lit. f DSGVO),
        etwa zur Durchsetzung unserer Forderungen oder zur Verfolgung von
        Straftaten.
      </li>
    </ul>
    <p>
      Selbstverständlich respektieren wir es, wenn uns deine personenbezogenen
      Daten nicht zur Unterstützung unserer Beziehung zu dir (insbesondere für
      Direktmarketing oder zu Marktforschungszwecken) überlassen willst.
    </p>
    <p>
      Wir geben persönliche Daten über Dich nur bekannt, wenn wir hierzu
      gesetzlich verpflichtet sind (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit.
      c DSGVO) bzw. sofern wir durch eine gerichtliche Entscheidung dazu
      verpflichtet sind oder wenn die Weitergabe erforderlich ist, um unsere
      Interessen, Allgemeinen Geschäftsbedingungen oder andere Vereinbarungen
      oder die Interessen Dritter durchzusetzen oder zu schützen
      (Rechtsgrundlage ist hier Art. 6 Abs. 1 lit. f DSGVO). Dies gilt
      entsprechend in Bezug auf die sonstige Verarbeitung der Daten.
    </p>
    <p>
      Unsere Mitarbeiter und die von uns beauftragten Dienstleistungsunternehmen
      sind von uns zur Verschwiegenheit und zur Einhaltung der Bestimmungen der
      DSGVO, des Bundesdatenschutzgesetzes und anderer gesetzlicher Regelungen
      verpflichtet. Der Zugriff auf personenbezogene Daten durch unsere
      Mitarbeiter ist auf die Mitarbeiter beschränkt, die die jeweiligen Daten
      aufgrund ihrer beruflichen Aufgaben benötigen.
    </p>
    <p>
      Konkret möchten wir Dich über die folgenden Zwecke, die dort erhobenen
      Daten und deren Verwendung informieren:
    </p>
    <h3>
      3.1. Verwendung personenbezogener Daten für die Registrierung / Nutzung
      unseres Online-Angebotes (Spielerlebnis)
    </h3>
    <p>
      Wir erheben für die Registrierung und die Nutzung unseres Online-Angebotes
      (Dein Spielerlebnis) personenbezogene Daten (Rechtsgrundlage ist hier Art.
      6 Abs. 1 lit. b DSGVO).
    </p>
    <p>
      Diese Daten sind für deine Registrierung bei uns und das Spielerlebnis
      erforderlich und werden ausschließlich für die Begründung, Durchführung
      oder Beendigung des Vertrages zwischen Dir und uns genutzt. Ohne diese
      Daten ist es uns nicht möglich, Dich am Spielerlebnis teilnehmen zu
      lassen. Die Daten deines Accounts verwenden wir, um dich beim Login zu
      authentifizieren und Anfragen zur Änderung von Kontoinformationen
      nachzugehen. Die von dir im Rahmen der Registrierung oder Anmeldung
      eingegebenen Daten werden von uns verarbeitet und verwendet, um deine
      Berechtigung zur Verwendung der App und insbesondere zur Verwaltung deines
      Nutzeraccounts zu verifizieren und mit dir in Kontakt zu treten, um dir
      technische oder rechtliche Hinweise, Updates, Sicherheitsmeldungen oder
      andere Nachrichten, die etwa die Verwaltung deines Nutzeraccounts
      betreffen, senden zu können.
    </p>
    <p>Folgende Daten werden erhoben:</p>
    <ul>
      <li>Spielername</li>
      <li>E-Mail-Adresse</li>
      <li>Passwort</li>
      <li>Ob Du 18 Jahre oder älter bist</li>
      <li>Single-Sign-On (SSO) mit deinem Apple oder Facebook Account</li>
      <li>oder Gastzugang</li>
    </ul>
    <p>
      In allen Fällen wird eine User ID für dich generiert, die dazu verwendet
      wird, dich als User unserer App zu identifizieren.
    </p>
    <p>
      Darüber hinaus übermitteln die Drittanbieter in der Regel zur
      Authentiofizierung weitere Basisinformationen zu deinem dortigen
      Nutzerprofil (u.a. E-Mail-Adresse und Profilbild). Diese
      Informationsübermittlung kannst du gegebenenfalls im Anmeldeverlauf
      einschränken. Nähere Informationen erhältst du bei den jeweiligen
      Drittanbietern:
    </p>
    <ul>
      <li>
        Apple Distribution International Limited, Hollyhill Industrial Estate,
        Hollyhill, Cork, Irland:
        <a href="https://www.apple.com/de/privacy"
          >https://www.apple.com/de/privacy</a
        >
      </li>
      <li>
        Facebook Ireland Ltd., 4 Grand Canal Square Grand Canal Harbour, Dublin
        2, Irland:
        <a href="https://www.facebook.com/policy.php"
          >https://www.facebook.com/policy.php</a
        >
      </li>
    </ul>
    <p>Die Datenverarbeitung ist dadurch gerechtfertigt, dass</p>
    <ul>
      <li>
        die Verarbeitung für die Erfüllung des Vertrags zwischen dir als
        Betroffenem und uns gemäß Art. 6 Abs. 1 lit. b DSGVO zur Nutzung der App
        erforderlich ist, und
      </li>
      <li>
        wir ein berechtigtes Interesse daran haben, die Funktionsfähigkeit und
        den fehlerfreien Betrieb der App zu gewährleisten, das hier deine Rechte
        und Interessen am Schutz deiner personenbezogenen Daten im Sinne von
        Art. 6 Abs. 1 lit. f DSGVO überwiegt.
      </li>
    </ul>
    <h3>3.2. Kontaktformular</h3>
    <p>
      Zur Kontaktaufnahme bei Fragen oder Anmerkungen kannst du unser
      Feedbackformular innerhalb der App verwenden. Deine Nachricht wird dann
      von unserem Kundensupport bearbeitet. In diesem Fall senden wir dir eine
      Eingangsbestätigung an diese E-Mail-Adresse und unser Kundensupport setzt
      sich bei Fragen mit dir in Verbindung.
    </p>
    <p>
      Die genannten Daten werden zum Zwecke der Beantwortung deines Feedbacks
      verarbeitet. Die Verarbeitung basiert auf der Rechtsgrundlage unseres
      berechtigten Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO, denn wir haben
      ein Interesse daran, dein Anliegen und deine Fragen zu klären. Sofern sich
      die Kontaktanfrage auf die Durchführung eines Vertrages bezieht, ist die
      Rechtsgrundlage für die Verarbeitung Deiner Kontaktanfrage Art. 6 Abs. 1
      lit. b DSGVO.
    </p>
    <h2>4. Gewinnspiele</h2>
    <p>
      Für Gewinnspiele erheben wir ggf. Angaben, damit wir dich, falls du
      gewinnst, kontaktieren können. Dies sind beispielsweise Anrede, Vorname,
      Nachname, deine E-Mail-Adresse. Gerne kannst Du uns optional weitere Daten
      mitteilen, das hilft uns dabei, unsere Gewinnspiele künftig besser zu
      planen. Im Rahmen der Teilnahme an Gewinnspielen hast Du ggf. auch die
      Möglichkeit, Dich für unseren Newsletter anzumelden. Bitte beachte dann
      hierzu die oben genannten Hinweise. Rechtsgrundlage für die
      Datenverarbeitung im Rahmen einer Teilnahme an Gewinnspielen ist Art. 6
      Abs. 1 lit. b DSGVO.
    </p>
    <h2>5. Empfänger von Daten</h2>
    <p>
      Eine Übermittlung deiner Daten an Dritte findet grundsätzlich nicht statt.
      Ausnahmen können sich ergeben, wenn
    </p>
    <ul>
      <li>
        du gemäß Art. 6 Abs. 1 lit. a DSGVO deine ausdrückliche Einwilligung
        dazu erteilt hast,
      </li>
      <li>
        die Weitergabe nach Art. 6 Abs. 1 lit. f DSGVO zur Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und
        kein Grund zur Annahme besteht, dass du ein überwiegendes schutzwürdiges
        Interesse an der Nichtweitergabe deiner Daten hast,
      </li>
      <li>
        für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c
        DSGVO eine gesetzliche Verpflichtung besteht,
      </li>
      <li>
        dies gesetzlich zulässig und nach Art. 6 Abs. 1 lit. b DSGVO für die
        Abwicklung von Vertragsverhältnissen mit dir erforderlich ist oder
      </li>
      <li>
        diese an einen in unserem Auftrag und auf unsere ausschließliche Weisung
        tätigen Dienstleister erfolgt, den wir sorgfältig ausgewählt haben (Art.
        28 Abs. 1 DSGVO) und mit dem wir einen entsprechenden Vertrag über die
        Auftragsverarbeitung (Art. 28 Abs. 3 DSGVO) geschlossen haben, der
        unseren Auftragnehmer u.a. zur Umsetzung angemessener
        Sicherheitsmaßnahmen verpflichtet und uns umfassende Kontrollbefugnisse
        einräumt.
      </li>
    </ul>
    <p>
      Über die in dieser Information bereits dargestellten Empfängern, geben wir
      ggf. Daten an insbesondere folgende Empfänger (Auftragsverarbeiter und
      Dritte) weiter, jedoch nur, wenn hierfür eine gesetzliche Grundlage (gem.
      Art 6 Abs. 1 lit. a, b, c oder f DSGVO, siehe oben) vorhanden ist oder
      gesetzlich zwingende Gründe bestehen:
    </p>
    <ul>
      <li>
        Dienstleistungsunternehmen, die zur Erbringung unserer Dienstleistungen
        erforderlich sind (insbesondere IT-Dienstleistungsunternehmen,
        Beratungsunternehmen)
      </li>
      <li>
        Unternehmen / Institutionen zur Unterstützung bei der Durchsetzung
        unserer Ansprüche (z.B. Inkassounternehmen)
      </li>
      <li>
        Öffentliche Stellen und Institutionen (Gesundheitsämter, Gerichte)
      </li>
      <li>Ermittlungsbehörden (Polizei, Staatsanwaltschaft)</li>
      <li>Erfüllungsgehilfen</li>
    </ul>
    <h2>6. Deine Rechte nach Art. 15 - 21 DSGVO</h2>
    <p>Du hast das Recht:</p>
    <ul>
      <li>
        Auskunft über deine von uns verarbeiteten personenbezogenen Daten zu
        verlangen. Insbesondere kannst du Auskunft über die Verarbeitungszwecke,
        die Kategorie der personenbezogenen Daten, die Kategorien von
        Empfängern, gegenüber denen deine Daten offengelegt wurden oder werden,
        die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
        Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen
        eines Beschwerderechts, die Herkunft deiner Daten, sofern diese nicht
        bei uns erhoben wurden, sowie über das Bestehen einer automatisierten
        Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
        Informationen zu deren Einzelheiten verlangen, Art. 15 DSGVO;
      </li>
      <li>
        die Berichtigung unrichtiger oder Vervollständigung deiner bei uns
        gespeicherten personenbezogenen Daten zu verlangen, Art. 16 DSGVO;
      </li>
      <li>
        die unverzügliche Löschung deiner bei uns gespeicherten
        personenbezogenen Daten zu verlangen, sofern die in Art. 17 DSGVO
        genannten Bedingungen erfüllt sind;
      </li>
      <li>
        die Einschränkung der Verarbeitung deiner personenbezogenen Daten zu
        verlangen, soweit Art. 18 DSGVO dies vorsieht;
      </li>
      <li>
        deine personenbezogenen Daten in einem den Voraussetzungen des Art. 20
        DSGVO entsprechenden Format zu erhalten oder die Übermittlung an einen
        anderen Verantwortlichen zu verlangen.
      </li>
    </ul>
    <p>
      Zur Geltendmachung deiner Rechte genügt eine Email an
      <a href="mailto:datenschutz@gluecksfaktor7.com">
        datenschutz@gluecksfaktor7.com</a
      >
    </p>
    <h3>6.1. Widerrufsrecht</h3>
    <p>
      Wenn Du eine Einwilligung zur Verarbeitung von Daten erteilt hast, kannst
      du diese jederzeit mit Wirkung für die Zukunft widerrufen. Durch den
      Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der
      Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
    </p>
    <h3>6.2. Widerspruchsrecht</h3>
    <p>
      Widerspruchsrecht nach Art. 21 DSGVO: Du hast das Recht, aus Gründen, die
      sich aus deiner besonderen Situation ergeben, jederzeit gegen die
      Verarbeitung der dich betreffenden personenbezogenen Daten, die aufgrund
      von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen;
      dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden
      die Dich betreffenden personenbezogenen Daten verarbeitet, um
      Direktwerbung zu betreiben, hast Du das Recht, jederzeit Widerspruch gegen
      die Verarbeitung der dich betreffenden personenbezogenen Daten zum Zwecke
      derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es
      mit solcher Direktwerbung in Verbindung steht.
    </p>
    <p>Die zuständige Aufsichtsbehörde für den Datenschutz für uns ist:</p>
    <p>
      Der Landesbeauftragte für den Datenschutz Niedersachsen<br />
      Prinzenstraße 5<br />30159 Hannover<br />Telefon: +49 (0511) 120 45 00<br />
      Telefax: +49 (0511) 120 45 99<br />E-Mail:
      <a href="mailto:poststelle@lfd.niedersachsen.de"
        >poststelle@lfd.niedersachsen.de</a
      >
    </p>
    <p>
      Wir freuen uns, wenn Du zuerst mit uns sprichst, damit wir mögliche
      Unklarheiten oder Unsicherheiten gemeinsam klären können.
    </p>

    <h2>7. Löschung</h2>
    <p>
      Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
      Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
      widerrufen werden oder sonstige Erlaubnisse entfallen (z.B., wenn der
      Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck
      nicht erforderlich sind).
    </p>
    <p>
      Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich
      zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese
      Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere
      Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
      steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung
      zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder
      zum Schutz der Rechte einer anderen natürlichen oder juristischen Person
      erforderlich ist.
    </p>
    <p>
      Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner
      im Rahmen der einzelnen Datenschutzhinweise dieser Datenschutzerklärung
      erfolgen.
    </p>

    <h2>8. Newsletter</h2>
    <p>
      Wir bieten die Möglichkeit, sich per Newsletter über News und Angebote zu
      informieren.
    </p>
    <p>
      Sofern Sie sich für einen Newsletter angemeldet haben, werden wir Ihre
      personenbezogenen Daten zum Zwecke des Newsletter-Versandes verarbeiten.
      Die Verarbeitung erfolgt freiwillig auf Basis Ihrer Einwilligung gem. Art.
      6 Abs. 1 lit. a DSGVO. Der Widerruf dieser Einwilligung ist jederzeit
      möglich. Die Einwilligung kann auch durch den Unsubscribe-Link am Ende des
      Newsletters aufgerufen werden. Selbstverständlich können Sie uns auch auf
      allen anderen Wegen (z.B. per Post) kontaktieren und Ihre Einwilligung
      widerrufen. Die Verarbeitung erfolgt bis zum Widerruf der Einwilligung.
      Die Rechtmäßigkeit, der bis zum Widerruf der Einwilligung erfolgten
      Verarbeitung, wird davon nicht berührt. Nach Widerruf der Einwilligung
      werden die personenbezogenen Daten noch für 6 Monate zum Zwecke der
      Rechtsverteidigung aufbewahrt. Die Rechtsgrundlage hierfür ist Art. 6 Abs
      .1 lit. f DSGVO.
    </p>
    <h2>9. Download der App</h2>
    <h3>9.1. Informationen, die beim Download erhoben werden</h3>
    <p>
      Beim Download der App werden bestimmte erforderliche Informationen an den
      von Ihnen ausgewählten App Store (z.B. Google Play oder Apple App Store)
      übermittelt, insbesondere können dabei der Nutzername, die E-Mail-Adresse,
      die Kundennummer Ihres Accounts, der Zeitpunkt des Downloads,
      Zahlungsinformationen sowie die individuelle Gerätekennziffer verarbeitet
      werden. Die Verarbeitung dieser Daten erfolgt ausschließlich durch den
      jeweiligen App Store und liegt außerhalb unseres Einflussbereiches.
    </p>
    <h3>9.2. Informationen, die automatisch erhoben werden</h3>
    <p>
      Im Rahmen Ihrer Nutzung der App erheben wir bestimmte Daten automatisch,
      die für die Nutzung der App erforderlich sind. Hierzu gehören: interne
      Geräte-ID, Version Ihres Betriebssystems, Zeitpunkt des Zugriffs.
    </p>
    <p>
      Diese Daten werden automatisch an uns übermittelt, aber nicht gespeichert,
      (1) um Ihnen den Dienst und die damit verbundenen Funktionen zur Verfügung
      zu stellen; (2) die Funktionen und Leistungsmerkmale der App zu verbessern
      und (3) Missbrauch sowie Fehlfunktionen vorzubeugen und zu beseitigen.
      Diese Datenverarbeitung ist dadurch gerechtfertigt, dass (1) die
      Verarbeitung für die Erfüllung des Vertrags zwischen Ihnen als Betroffener
      und uns gemäß Art. 6 Abs. 1 lit. b) DSGVO zur Nutzung der App erforderlich
      ist, oder (2) wir ein berechtigtes Interesse daran haben, die
      Funktionsfähigkeit und den fehlerfreien Betrieb der App zu gewährleisten
      und einen markt- und interessengerechten Dienst anbieten zu können, das
      hier Ihre Rechte und Interessen am Schutz Ihrer personenbezogenen Daten im
      Sinne von Art. 6 Abs. 1 lit. f) DSGVO überwiegt.
    </p>
    <p>
      Zur Analyse des Nutzungsverhalten in der App nutzen wir außerdem den
      Service Google Firebase
    </p>
    <p>
      Firebase ist eine Echtzeit-Datenbank, mit der sich Echtzeitinformationen
      in die eigene App einbetten lassen. Hierbei werden die Nutzerdaten
      anonymisiert an Firebase übermittelt. Firebase ist eine Google-Tochter und
      hat seinen Sitz in San Francisco (CA), USA. Die Datenschutzerklärung von
      Firebase findest Du unter
      <a href="https://www.firebase.com/terms/privacy-policy.html"
        >https://www.firebase.com/terms/privacy-policy.html</a
      >. Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DSGVO.
    </p>
    <h2>10. Zahlungsabwicklung</h2>
    <h3>10.1. Adyen</h3>
    <p>
      Wenn Du Zahlungen bei {{ $whitelabel.branding.productName }} mit einer
      Kredit- oder Debitkarte vornimmst, musst Du unserem Drittanbieter
      personenbezogene Daten, wie deine Kreditkartennummer, zur Verfügung
      stellen.
    </p>
    <p>
      Wenn Du bei uns per Kredit- oder Debitkarte bezahlst, erfolgt die
      Abwicklung über unseren Payment-Gateway-Anbieter Adyen B.V., Simon
      Carmiggeltstraat 6—50, 5. Stock, 1011 DJ Amsterdam, Niederlande („Adyen“).
    </p>
    <p>
      Du wirst deine Kredit- oder Debitkarteninformationen direkt an Adyen
      weitergeben. Informationen, die Du Adyen zur Verfügung stellst, stehen
      nicht unter unserer Kontrolle und unterliegen der Datenschutzerklärung von
      Adyen, die hier verfügbar ist:
      <a href="https://www.adyen.com/policies-and-disclaimer/privacy-policy"
        >https://www.adyen.com/policies-and-disclaimer/privacy-policy</a
      >.
    </p>
    <p>Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO.</p>
    <p>Wir speichern keine Kreditkartennummern.</p>

    <h3>10.2. PayPal</h3>
    <p>
      Wir bieten die Möglichkeit, Zahlungen über den Zahlungsdienstleister
      PayPal (PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
      L-2449 Luxembourg) zu leisten. Hierbei können insbesondere die folgenden
      Daten an Paypal übermittelt werden:
    </p>
    <ul>
      <li>Vorname</li>
      <li>Nachname</li>
      <li>Adresse</li>
      <li>E-Mail-Adresse</li>
      <li>Telefonnummer</li>
    </ul>
    <p>
      Dies Übermittlung entspricht im Falle Ihrer Nutzung von Paypal unserem
      berechtigten Interesse, eine effiziente und sichere Zahlungsmethode
      anzubieten (Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. f DSGVO). Die
      Datenweitergabe ist dann für die Vertragserfüllung erforderlich ist
      (Rechtsgrundlage ist dann Art. 6 Abs. 1 lit b. DSGVO).
    </p>
    <p>
      Wir erhalten von Paypal eine Auskunft, ob die Zahlung erfolgreich
      durchgeführt oder abgelehnt wurde oder eine Überprüfung aussteht.
    </p>
    <p>
      Die Verarbeitung der Daten ist hierbei weder gesetzlich vorgeschrieben und
      nur dann vertraglich erforderlich, wenn Du diese Zahlungsart auswählst.
      Ohne die Übermittlung Deiner personenbezogenen Daten können wir eine
      Zahlung über PayPal leider nicht durchführen.
    </p>
    <p>
      Details zur Zahlung mit Paypal (insbesondere zu etwaig von Paypal
      durchgeführten Bonitätsprüfungen oder Datenweitergaben durch Paypal)
      entnehmen Sie den AGB und den Datenschutzbestimmungen von Paypal unter:
      <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
        >https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a
      >.
    </p>
    <h2>11. Informationen zu weiteren Funktionen unserer Website</h2>
    <h3>11.1. Cookies</h3>
    <p>
      Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
      bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen
      Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien,
      die auf deinem Endgerät abgelegt werden. Einige der von uns verwendeten
      Cookies werden nach dem Ende der Browser-Sitzung, also nach Schließen
      deines Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies
      verbleiben auf deinem Endgerät und ermöglichen uns oder unseren
      Partnerunternehmen, deinen Browser beim nächsten Besuch wiederzuerkennen
      (persistente Cookies). Wir verwenden Cookies, um Inhalte und Anzeigen zu
      personalisieren und die Zugriffe auf unsere Website zu analysieren.
      Außerdem geben wir Informationen zu deiner Nutzung unserer Website an
      unsere Partner für soziale Medien, Werbung und Analysen weiter.
    </p>
    <p>
      Die rechtliche Grundlage für die Verwendung von Cookies ist deine
      Einwilligung. Wir setzen ein oder mehrere Cookies nur dann, wenn Du der
      Verwendung von Cookies in dem Cookie-Popup, das beim Besuch unserer
      Website erscheint, zugestimmt hast. Die Rechtsgrundlage ist dann Art. 6
      Abs. 1 lit. a DSGVO, § 25 Abs. 1 TDDDG. Du kannst diese Einwilligung
      jederzeit mit Wirkung für die Zukunft widerrufen.
    </p>
    <h3>11.2. Nutzung von Google Analytics</h3>
    <p>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
      Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA
      94043, USA. Google Analytics verwendet sog. „Cookies“. Das sind
      Textdateien, die auf deinem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Dich ermöglichen. Die durch den
      Cookie erzeugten Informationen über deine Benutzung der Website wie:
    </p>
    <ul>
      <li>Browser-Typ/-Version,</li>
      <li>verwendetes Betriebssystem,</li>
      <li>Referrer-URL (die zuvor besuchte Seite),</li>
      <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
      <li>Uhrzeit der Serveranfrage</li>
    </ul>
    <p>
      werden in der Regel an einen Server von Google in den USA übertragen und
      dort gespeichert. Rechtsgrundlage ist Deine Einwilligung gem. Art. 6 Abs.
      1 lit. a DSGVO, § 25 Abs. 1 TDDDG. Ein etwaiger Drittstaaten-Transfer in
      die USA erfolgt auf Basis des Angemessenheitsbeschluss der Europäischen
      Kommission zum Datenschutzrahmen EU-USA (EU-US Data Privacy Framework) vom
      10.07.2023
    </p>
    <h3>IP-Anonymisierung</h3>
    <p>
      Bei der Aktivierung der IP-Anonymisierung auf dieser Website wird deine
      IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
      Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
      Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
      volle IP-Adresse an einen Server von Google in den USA übertragen und dort
      gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
      Informationen benutzen, um deine Nutzung der Website auszuwerten, um
      Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit
      der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
      gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
      Analytics von deinem Browser übermittelte IP-Adresse wird nicht mit
      anderen Daten von Google zusammengeführt.
    </p>
    <p>
      Du kannst die Speicherung der Cookies durch eine entsprechende Einstellung
      deiner Browser-Software oder dadurch, dass Du nicht einwilligst,
      verhindern; wir weisen Dich jedoch darauf hin, dass Du in diesem Fall
      gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
      wirst nutzen können. Du kannst darüber hinaus die Erfassung der durch den
      Cookie erzeugten und auf deiner Nutzung der Website bezogenen Daten (inkl.
      deiner IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
      Google verhindern, indem Du das unter dem folgenden Link verfügbare
      Browser-Plugin herunterlädst und installierst:
      <a href="http://tools.google.com/dlpage/gaoptout?hl=de"
        >http://tools.google.com/dlpage/gaoptout?hl=de</a
      >.
    </p>
    <p>
      Alternativ zum Browser-Add-On, insbesondere bei Browsern auf mobilen
      Endgeräten, kannst Du die Erfassung durch Google Analytics zudem
      verhindern, indem Du auf diesen Link klickst:
      <button
        class="button-empty text-decoration-underline bold"
        @click.prevent="$emit('cookies')"
      >
        Cookie-Einstellungen</button
      >. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung deiner
      Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur
      in diesem Browser und nur für unsere Website und wird auf deinem Gerät
      abgelegt. Löschst Du die Cookies in diesem Browser, musst Du das
      Opt-out-Cookie erneut setzen.
    </p>
    <p>
      Wir nutzen Google Analytics weiterhin dazu, Daten aus Double-click-Cookies
      und auch Google Ads zu statistischen Zwecken auszuwerten. Solltest Du dies
      nicht wünschen, kannst Du dies über den
      <a href="https://myadcenter.google.com/home?hl=de"
        >Anzeigenvorgaben-Manager</a
      >
      deaktivieren.
    </p>
    <h3>11.4. Facebook-Connect</h3>
    <p>
      Wir bieten Dir die Möglichkeit Dich bei uns über Facebook-Connect
      anzumelden. Rechtsgrundlage hierfür ist deine Einwilligung gem. Art. 6
      Abs. 1 lit. a DSGVO:
    </p>
    <p>
      „Mit dem Klick auf „mit Facebook registrieren“ willige ich ein, dass durch
      Facebook-Connect automatisch Daten an die
      {{ $whitelabel.branding.companyName }} übermittelt und anschließend
      verarbeitet werden. Dies betrifft mein Profilbild, meinen Namen und mein
      Geburtsdatum. Die Datenschutzerklärung habe ich gelesen. Diese
      Einwilligung kann ich jederzeit durch eine E-Mail an
      <a href="mailto:datenschutz@gluecksfaktor7.com"
        >datenschutz@gluecksfaktor7.com</a
      >
      widerrufen.“
    </p>
    <p>
      Eine zusätzliche Registrierung ist somit nicht erforderlich. Zur Anmeldung
      wirst Du auf die Seite von Facebook weitergeleitet, wo Du Dich mit deinen
      Anmeldedaten von Facebook anmelden kannst. Hierdurch wird dein
      Facebook-Profil und {{ $whitelabel.branding.productName }} miteinander
      verknüpft. Durch diese Verknüpfung erhalten wir automatisch von Facebook
      die nachfolgenden Informationen:
    </p>
    <ul>
      <li>Name</li>
      <li>Geburtsdatum</li>
      <li>Profilbild</li>
    </ul>
    <p>
      Wir nutzen diese Daten ausschließlich dafür, dass Du am Spielerlebnis
      teilnehmen kannst und für die in dieser Datenschutzerklärung genannten
      weiteren Zwecke.
    </p>
    <p>
      Weitere Informationen zu Facebook-Connect und den
      Privatsphäre-Einstellungen findest Du in den Datenschutzhinweisen und den
      Nutzungsbedingungen von Facebook.
    </p>
    <h3>11.5. Google Ads</h3>
    <p>
      Wir haben auf unserer Website Google Ads integriert. Google Ads ist ein
      Dienst von Google Ireland Limited („Google“), Gordon House, Barrow Street,
      Dublin 4, Irland, um Nutzern zielgerichtet Werbung anzuzeigen. Google Ads
      nutzt Cookies und weitere Browser-Technologien um Nutzerverhalten
      auszuwerten und Nutzer wiederzuerkennen.
    </p>
    <p>
      Google Ads sammelt Informationen über das Besucherverhalten auf
      verschiedenen Websites. Diese Informationen werden verwendet, um die
      Relevanz der Werbung zu optimieren. Des Weiteren liefert Google Ads
      gezielt Werbung auf der Grundlage von Verhaltensprofilen und geografischer
      Lage. Dem Anbieter werden Ihre IP-Adresse und weitere
      Identifikationsmerkmale wie dein User-Agent übermittelt.
    </p>
    <p>
      Wenn du bei einem Google Dienst registriert bist, kann Google Ads den
      Besuch deinem Konto zuordnen. Selbst wenn du nicht bei Google registriert
      bist oder du dich nicht eingeloggt hast, ist es möglich, dass der Anbieter
      deine IP-Adresse und weitere Identifikationsmerkmale herausfindet und
      speichert.
    </p>
    <p>
      Die Weitergabe deiner Daten erfolgt in diesem Fall an den Betreiber von
      Google Ads, die Google Ireland Limited, Gordon House, Barrow Street,
      Dublin 4, Irland.
    </p>
    <p>
      Der Einsatz von Google Ads erfolgt auf Grundlage deiner Einwilligung gemäß
      Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TDDDG.
    </p>
    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU-USA (EU-US Data Privacy Framework) vom 10.07.2023.
    </p>
    <p>
      Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
      beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere
      Hinweise findest du in der Datenschutzerklärung für Google Ads:
      <a href="https://policies.google.com/privacy"
        >https://policies.google.com/privacy</a
      >.
    </p>
    <h3>11.6. Google AdSense</h3>
    <p>
      Unsere Website verwendet Google AdSense. Anbieter ist die Google Inc.,
      1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
    </p>
    <p>
      Google AdSense dient der Einbindung von Werbeanzeigen und setzt Cookies.
      Cookies sind kleine Textdateien, die dein Webbrowser auf deinem Endgerät
      speichert, um die Nutzung der Website analysieren. Google AdSense setzt
      außerdem Web Beacons ein. Web Beacons sind unsichtbare Grafiken, die eine
      Analyse des Besucherverkehrs auf unserer Wesite ermöglichen.
    </p>
    <p>
      Durch Cookies und Web Beacons erzeugten Informationen werden an Server von
      Google übertragen und dort gespeichert. Serverstandort sind die USA.
      Google kann diese Informationen an Vertragspartner weiterreichen. Deine
      IP-Adresse wird Google jedoch nicht mit anderen von Ihnen gespeicherten
      Daten zusammenführen.
    </p>
    <p>
      Die Speicherung von AdSense-Cookies erfolgt auf Grundlage von Art. 6 Abs.
      1 lit. a DSGVO, § 25 TDDDG.
    </p>
    <p>
      Mit einem modernen Webbrowser kannst du das Setzen von Cookies überwachen,
      einschränken und unterbinden. Die Deaktivierung von Cookies kann eine
      eingeschränkte Funktionalität unserer Website zur Folge haben. Durch deine
      Einwilligung erklärst du dich mit der Bearbeitung der über dich erhobenen
      Daten durch Google in der zuvor beschriebenen Art und Weise sowie dem
      zuvor benannten Zweck einverstanden.
    </p>
    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU-USA (EU-US Data Privacy Framework) vom 10.07.2023.
    </p>
    <h3>11.7. Bing Ads</h3>
    <p>
      Wir haben auf unserer Website Bing Ads integriert. Bing Ads ist ein Dienst
      von Microsoft Corporation, um Nutzern zielgerichtet Werbung anzuzeigen.
      Bing Ads nutzt Cookies und weitere Browser- Technologien um
      Nutzerverhalten auszuwerten und Nutzer wiederzuerkennen.
    </p>
    <p>
      Bing Ads sammelt Informationen über das Besucherverhalten auf
      verschiedenen Websites. Diese Informationen werden verwendet, um die
      Relevanz der Werbung zu optimieren. Des Weiteren liefert Bing Ads gezielt
      Werbung auf der Grundlage von Verhaltensprofilen und geografischer Lage.
      Dem Anbieter werden deine IP-Adresse und weitere Identifikationsmerkmale
      wie dein User-Agent übermittelt.
    </p>
    <p>
      Die Weitergabe Ihrer Daten erfolgt in diesem Fall an den Betreiber von
      Bing Ads, die Microsoft Corporation, One Microsoft Way Redmond, WA
      98052-6399, United States.
    </p>
    <p>
      Der Einsatz von Bing Ads erfolgt auf Grundlage deiner Einwilligung gemäß
      Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TDDDG.
    </p>
    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU-USA (EU-US Data Privacy Framework) vom 10.07.2023.
    </p>
    <p>
      Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
      beeinflussbar, sondern wird von Microsoft Corporation bestimmt. Weitere
      Hinweise finden Sie in der Datenschutzerklärung für Bing Ads:
      <a href="https://privacy.microsoft.com/de-de/privacystatement"
        >https://privacy.microsoft.com/de-de/privacystatement</a
      >.
    </p>
    <h3>11.8. Facebook Pixel</h3>
    <p>
      Wir verwenden Facebook Pixel von Meta Platforms Ireland Limited, 4 Grand
      Canal Square, Grand Canal Harbour, Dublin 2, Ireland, um sogenannte Custom
      Audiences zu erstellen, also Besuchergruppen unseres Onlineangebotes zu
      segmentieren, Conversion-Rates zu ermitteln und diese anschließend zu
      optimieren. Dies geschieht insbesondere dann, wenn du mit Werbeanzeigen,
      die wir mit Meta Platforms Ireland Limited geschaltet haben, interagierst.
    </p>
    <p>
      Der Einsatz von Facebook Pixel erfolgt auf Grundlage deiner Einwilligung
      gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1TDDDG.
    </p>
    <p>
      Ein etwaiger Drittstaaten-Transfer in die USA erfolgt auf Basis des
      Angemessenheitsbeschluss der Europäischen Kommission zum Datenschutzrahmen
      EU-USA (EU-US Data Privacy Framework) vom 10.07.2023. Die konkrete
      Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar,
      sondern wird von Meta Platforms Ireland Limited bestimmt. Weitere Hinweise
      finden Sie in der Datenschutzerklärung für Facebook Pixel:
      <a href="https://www.facebook.com/privacy/explanation"
        >https://www.facebook.com/privacy/explanation</a
      >.
    </p>

    <h2>12. Zugriffsberechtigungen der App</h2>
    <p>
      Für bestimmte Funktionen der App ist es notwendig, dass du uns vor der
      Nutzung eine Zugriffsberechtigung für ausgewählte Funktionen deines
      Smartphones erteilst. Die App erfordert folgende Berechtigungen:
    </p>
    <b-table :items="items"> </b-table>
    <p>
      Die Verarbeitung und Verwendung der obigen Berechtigungen erfolgt zur
      Bereitstellung des Dienstes. Der Internetzugriff ist für die Nutzung
      erforderlich, daher ist die Rechtsgrundlage der Verarbeitung
      <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art.6 Abs. 1 lit.b DSGVO</a
      >, da du mit uns durch die Benutzung unserer Services einen
      Nutzungsvertrag hast.
    </p>
    <p>
      Die optionalen Berechtigungen finden nur aufgrund deiner Einwilligung gem.
      <a href="https://dsgvo-gesetz.de/art-6-dsgvo/"
        >Art. 6 Abs, 1 lit.b DSGVO</a
      >
      statt und kann auch auf einzelne Dienste reduziert werden, z.B. nur
      Internetnutzung um Nachrichten ohne Bilder oder Standortdaten zu
      versenden.
    </p>
    <h2>13. Änderung unserer Datenschutzbestimmungen</h2>
    <p>Diese Datenschutzerklärung hat den Stand Oktober 2024.</p>
    <p>
      Durch die Weiterentwicklung unserer App und Angebote oder aufgrund
      geänderter gesetzlicher beziehungsweise behördlicher Vorgaben, kann es
      notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils
      aktuelle Datenschutzerklärung kann jederzeit innerhalb der App oder über
      unsere Website unter dem vorliegenden Link von dir abgerufen werden.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyTextDe",
  data() {
    return {
      items: [
        {
          Berechtigung: "Internetzugriff",
          Grund:
            "Dieser wird benötigt, um die Nachrichten an die Kommunikationsteilnehmer\*innen zu schicken.",
        },
        {
          Berechtigung: "Kamerazugriff (optional)",
          Grund:
            "Dieser wird benötigt, damit du Fotos anfertigen und über die App versenden kannst. Außerdem ermöglicht der Kamerazugriff das Scannen von QR-Codes.",
        },
        {
          Berechtigung: "Mikrofonzugriff (optional)",
          Grund:
            "Dieser wird benötigt, damit du Sprachnachrichten verschicken kannst.",
        },
        {
          Berechtigung: "Standortzugriff (optional)",
          Grund:
            "Dieser wird benötigt, wenn du deinen Standort mit einem/r Kommunikationsteilnehmer\*in teilen möchtest.",
        },
        {
          Berechtigung: "Hintergrund Standortzugriff (optional)",
          Grund:
            "Dieser wird benötigt, wenn du deinen Standort über einen Zeitraum teilen willst.",
        },
        {
          Berechtigung: "Kontaktzugriff (optional)",
          Grund:
            "Dieser wird benötigt, damit du Kontakte aus deinem Telefonbuch in die App laden und speichern kannst. Die Kontaktdaten werden dabei lokal in der App gespeichert und nicht auf Delta Chat Server weitergeleitet und gespeichert.",
        },
        {
          Berechtigung: "Speicher (optional)",
          Grund:
            "Sie können Bilder und Dateien aus der App auf Ihrem Endgerät speichern. ",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.table tbody tr {
  text-transform: none;
  text-align: left;
}
</style>

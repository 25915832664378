<template>
  <b-modal
    id="random-slot-popup"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <div id="icon-random-slot" class="gradient-indent to-center-abs">
        <img
          draggable="false"
          src="@/assets/img/header/random-slot_fill.svg"
          class="dead-center"
        />
      </div>
      <h1 class="text-white text-uppercase bold text-center">
        {{ $t("lobby.popups.random_slot-title") }}
      </h1>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template slot="default"
      ><p>
        {{ $t("lobby.popups.random_slot") }}
      </p>
    </template>
    <template slot="modal-footer"
      ><button class="ok-button-green round-new lg" @click="handleFeelLucky">
        {{ $t("lobby.popups.random_slot-button") }}
      </button></template
    >
  </b-modal>
</template>

<script>
export default {
  name: "RandomSlotPopup",
  methods: {
    handleFeelLucky() {
      this.$emit("feel-lucky");
      this.$bvModal.hide("random-slot-popup");
      this.$store.commit("disableRandomSlotPopup");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 654px;
$modal-height: 259px;
#random-slot-popup {
  .modal-header {
    align-items: center;
    justify-content: center;
    padding: 32px 0 0 0;

    #icon-random-slot {
      width: 72px;
      height: 72px;
      border: 1.3px solid #ffe600;
      border-radius: 100%;
      top: -40px;
    }
  }
  .modal-footer {
    padding: 0;
    border: 0;
    justify-content: center;
    margin-bottom: 10px;
  }
  .modal-body {
    justify-content: center;
  }
  .modal-dialog {
    height: 100%;
    width: 100%;
    padding-right: 15px;
    height: $modal-height;
    max-width: $modal-width;
    margin: 95px auto;
  }

  .modal-content {
    border: 2.9px solid $text-accent;
    color: white;
    text-align: center;
    height: $modal-height;
    width: $modal-width;

    p {
      font-size: 26px;
      color: $text-light-gray;
      margin: 0;
    }
  }
}
</style>
